<template>
<div class="base-container">
    <div class="search-box">
        <div class="search-input-wrapper">
            <el-input class="search-content" size="medium" placeholder="输入开发商名称、法人代表等关键字进行查询"
                        v-model="params.keyStr"></el-input>
            <el-button type="primary" size="small" @click="search()">查询</el-button>
        </div>
        <div class="right-part">
            <el-radio-group v-model="typeText" @change="projectChange">
                <el-radio-button v-if="utils.isDisplay('businessProject:view')" label="sys">业务系统项目(<span class="count-num" v-text="total"></span>)</el-radio-button>
                <el-radio-button label="own">我的项目(<span class="count-num" v-text="own_total"></span>)</el-radio-button>
            </el-radio-group>
            <!-- <el-select class="range-type" size="small" v-model="range">
                <el-option value="byName" label="按名称"></el-option>
                <el-option value="byrate" label="按销售比例"></el-option>
            </el-select> -->
            <div class="range-type" v-if="typeText=='own'" @click="addNewProject"><i class="el-icon-plus"></i> 新建项目</div>
        </div>
    </div>
    <div class="flex-colum">
        <div class="sys_pro" v-if="typeText=='sys'">
            <div :class="isSearchSys?'hidden':''">
                <div class="search-result-box">
                    <ul v-if="projectList.length > 0">
                        <li v-for="(item,index) in projectList" :key="index">
                            <div class="item_box">
                                <img class="img-project" v-if="imgShow"
                                :src="imgStr[index]" onerror="this.src='pics/no_pic.png';this.onerror=null"/>
                                <!-- <img class="img-project" src="../../assets/img/pics/90006349.jpg" /> -->
                                <div class="project-name">
                                    <div class="pro_name" v-text="item.proName +' '+ item.stageDescription" @click="GoProjectDetails(item,item.proName+' '+item.stageDescription+' ',item.id,'analysisDetails',true,'sys')"></div>
                                    <div class="pro_overview" v-if="utils.isDisplay('data:overview')" @click="goProOverview(item,'sys')">项目概览</div>
                                </div>
                                <div class="project-info" @click="GoProjectDetails(item,item.proName+' '+item.stageDescription+' ',item.id,'analysisDetails',true,'sys')">
                                    <div class="projec-info-item">
                                        <div class="option">&emsp;开发商：</div>
                                        <div class="content" v-text="item.taxpayerName" :title="item.taxpayerName"></div>
                                    </div>
                                    <div class="projec-info-item k">
                                        <div class="option">项目地址：</div>
                                        <div class="content" v-text="item.address" :title="item.address"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">销售比例：</div>
                                        <div class="content" v-text="item.xsbl || '——'"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">法人代表：</div>
                                        <div class="content" v-text="item.legalPerson"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">行政区划：</div>
                                        <div class="content" v-text="item.administrativeName || '——'"
                                            :title="item.administrativeName">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="line"></div>
                            <div class="tag-show">
                                <el-tag size="medium" :color="tagColor[key]" v-for="(value,key) in tagArr[index]" :key="key">
                                    <div :class="'type'+key">
                                        {{ value }}
                                    </div>
                                </el-tag>
                            </div>
                            <div class="nav_box">
                                <div class="nav_item" @click="GoProjectDetails(item,item.proName+' '+item.stageDescription+' ',item.id,'analysisDetails',false,'sys')">建设方分析</div>
                                <div class="nav_item" @click="GoProjectDetails(item,item.proName+' '+item.stageDescription+' ',item.id,'constructionDetails',false,'sys')">施工方分析</div>
                                <div class="nav_item" @click="GoProjectDetails(item,item.proName+' '+item.stageDescription+' ',item.id,'portraitProject',false,'sys')">项目信息</div>
                                <div class="nav_item" @click="GoProjectDetails(item,item.proName+' '+item.stageDescription+' ',item.id,'flfxpg',false,'sys')">风险分析</div>
                            </div>
                        </li>
                    </ul>
                    <div v-else class="nullrelation"></div>
                </div>
                <div class="page-box">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        background
                        layout="prev,pager,next,total,jumper"
                        :total=total
                        :current-page=params.pageNum>
                    </el-pagination>
                </div>
            </div>
            <p v-if="isShowSearch&&showSearchSys" @click="sysProMore" class="list_more">{{sysSearchText}}</p>
            
        </div>
        <div class="sys_pro" v-if="typeText=='own'">
            <div :class="isSearchOwn?'hidden':''">
                <div class="search-result-box">
                    <ul v-if="own_projectList.length > 0">
                        <li v-for="(item,index) in own_projectList" :key="index">
                            <span class="pro_tag" :class="item.isGet==0?'ready_bg':'success_bg'" v-if="item.isGet!=2">{{item.isGet==0?'数据准备中' : (item.isGet==1?'数据准备完成' : '')}}</span>
                            <div class="item_box">
                               <img class="img-project" src="pics/no_pic.png" />
                                <div class="project-name">
                                    <div class="pro_name" v-text="item.proName" @click="GoProjectDetails(item,item.proName,item.id,'analysisDetails',true,'own')"></div>
                                    <div class="pro_overview" v-if="utils.isDisplay('data:overview')" @click="goProOverview(item,'own')">项目概览</div>
                                </div>
                                <div class="project-info" @click="GoProjectDetails(item,item.proName,item.id,'analysisDetails',true,'own')">
                                    <div class="projec-info-item">
                                        <div class="option">&emsp;开发商：</div>
                                        <div class="content" v-text="item.taxpayerName" :title="item.taxpayerName"></div>
                                    </div>
                                    <div class="projec-info-item k">
                                        <div class="option">项目地址：</div>
                                        <div class="content" v-text="item.address" :title="item.address"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">销售比例：</div>
                                        <div class="content" v-text="item.xsbl || '——'"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">法人代表：</div>
                                        <div class="content" v-text="item.legalPerson"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">行政区划：</div>
                                        <div class="content" v-text="item.administrativeName || '——'"
                                            :title="item.administrativeName">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="line"></div>
                            <div class="tag-show">
                                <el-tag size="medium" :color="tagColor[key]" v-for="(value,key) in own_tagArr[index]" :key="key">
                                    <div :class="'type'+key">
                                        {{ value }}
                                    </div>
                                </el-tag>
                            </div>
                            <div class="nav_box">
                                <div class="nav_item" @click="GoProjectDetails(item,item.proName,item.id,'analysisDetails',false,'own')">建设方分析</div>
                                <div class="nav_item" @click="GoProjectDetails(item,item.proName,item.id,'constructionDetails',false,'own')">施工方分析</div>
                                <div class="nav_item" @click="GoProjectDetails(item,item.proName,item.id,'portraitProject',false,'own')">项目信息</div>
                                <div class="nav_item" @click="GoProjectDetails(item,item.proName,item.id,'flfxpg',false,'own')">风险分析</div>
                            </div>
                        </li>
                    </ul>
                    <div v-else class="nullrelation"></div>
                </div>
                <div class="page-box">
                    <el-pagination
                        @current-change="ownHandleCurrentChange"
                        background
                        layout="prev,pager,next,total,jumper"
                        :total=own_total
                        :current-page=own_params.pageNum>
                    </el-pagination>
                </div>
            </div>
            <p v-if="isShowSearch&&showSearchOwn" @click="ownProMore" class="list_more">{{ownSearchText}}</p>
        </div>
        <!-- 外部数据，只有搜索时展示 -->
        <div class="online_sign sys_pro" v-if="isShowSearch">
            <div class="out_header">
                <p>
                    <span>外部数据搜索结果：</span>
                    <span class="os_tag">网签({{os_total}}) </span>
                    <span class="os_tag">&nbsp;&nbsp;互联网数据({{net_total}})</span>
                </p>
            </div>
            <p v-if="utils.isDisplay('projectPublic:view')" class="out_tag">以下信息来自<span class="os_tag">网签项目数据({{os_total}})</span></p>
            <div v-if="utils.isDisplay('projectPublic:view')" :class="isSearchOs?'os_hidden':''">
                <div class="search-result-box">
                    <ul v-if="os_projectList.length > 0">
                        <li v-for="(item,index) in os_projectList" :key="index">
                            <div class="item_box">
                                <!-- <img class="img-project" v-if="imgShow"
                                :src="imgStr[index]" onerror="this.src='pics/no_pic.png';this.onerror=null"/> -->
                                <img class="img-project" src="pics/no_pic.png" />
                                <div class="project-name">
                                    <div class="pro_name" @click="goOsDetail(item)" v-text="item.projectName"></div>
                                    <div class="pro_overview" @click="openAdd(item)">+ 添加</div>
                                </div>
                                <div class="project-info" @click="goOsDetail(item)">
                                    <div class="projec-info-item">
                                        <div class="option">&emsp;开发商：</div>
                                        <div class="content" v-text="item.company" :title="item.company"></div>
                                    </div>
                                    <div class="projec-info-item k">
                                        <div class="option">项目地址：</div>
                                        <div class="content" v-text="item.address" :title="item.address"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">销售比例：</div>
                                        <div class="content" v-text="item.salePercent || '——'"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">行政区划：</div>
                                        <div class="content" v-text="item.province+item.city+item.county || '——'"
                                            :title="item.province+item.city+item.county">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div v-else class="nullrelation"></div>
                </div>
                <div class="page-box">
                    <el-pagination
                        @current-change="osHandleCurrentChange"
                        background
                        layout="prev,pager,next,total,jumper"
                        :total=os_total
                        :current-page=os_pageNum>
                    </el-pagination>
                </div>
            </div>
            <p v-if="isShowSearch&&utils.isDisplay('projectPublic:view')" @click="osProMore" class="list_more">{{osSearchText}}</p>
            <!-- 互联网数据 -->
            <p v-if="utils.isDisplay('internetProject:view')" class="out_tag">以下信息来自<span class="os_tag">互联网数据({{net_total}})</span></p>
            <div v-if="utils.isDisplay('internetProject:view')" :class="isSearchNet?'os_hidden':''">
                <div class="search-result-box">
                    <ul v-if="net_projectList.length > 0">
                        <li v-for="(item,index) in net_projectList" :key="index">
                            <div class="item_box">
                                <!-- <img class="img-project" v-if="imgShow"
                                :src="imgStr[index]" onerror="this.src='pics/no_pic.png';this.onerror=null"/> -->
                                <img class="img-project" src="pics/no_pic.png" />
                                <div class="project-name">
                                    <div class="pro_name" @click="goNetDetail(item)" v-text="item.projectName"></div>
                                    <div class="pro_overview" @click="openAdd(item)">+ 添加</div>
                                </div>
                                <div class="project-info" @click="goNetDetail(item)">
                                    <div class="projec-info-item">
                                        <div class="option">&emsp;开发商：</div>
                                        <div class="content" v-text="item.company" :title="item.company"></div>
                                    </div>
                                    <div class="projec-info-item k">
                                        <div class="option">项目地址：</div>
                                        <div class="content" v-text="item.address" :title="item.address"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">销售比例：</div>
                                        <div class="content" v-text="item.salePercent || '——'"></div>
                                    </div>
                                    <div class="projec-info-item">
                                        <div class="option">行政区划：</div>
                                        <div class="content" v-text="item.province+item.city+(item.county||'') || '——'"
                                            :title="item.province+item.city+item.county">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div v-else class="nullrelation"></div>
                </div>
                <div class="page-box">
                    <el-pagination
                        @current-change="netHandleCurrentChange"
                        background
                        layout="prev,pager,next,total,jumper"
                        :total=net_total
                        :current-page=net_pageNum>
                    </el-pagination>
                </div>
            </div>
            <p v-if="isShowSearch" @click="netProMore" class="list_more">{{netSearchText}}</p>
        </div>
        
    </div>
    <div class="feed_box" v-show="isAdd">
        <div class="feed_card">
            <div class="feed_header">
                <div class="feed_title">{{addTxt}}</div>
                <div class="el-icon-close feed_close" @click="close()"></div>
            </div>
            <div class="feed_form">
                <div class="feed_left">
                    <div class="feed_question">项目名称：</div>
                    <div class="feed_question">建设方名称：</div>
                    <div class="feed_question">项目区域：</div>
                    <div class="feed_question">项目地址：</div>
                </div>
                <div class="feed_right">
                    <el-input v-model="add_proName" placeholder="请输入项目名称"></el-input>
                    <el-input v-model="add_company" placeholder="请输入建设方名称"></el-input>
                   <el-cascader
                            size="large"
                            :options="areaData"
                            v-model="selectedOptions"
                            @change="areaHandleChange">
                    </el-cascader>
                    <el-input v-model="add_address" placeholder="请输入项目地址"></el-input>
                </div>
            </div>
            <div class="feed_btn">
                <div class="feed_cancel" @click="close()">取消</div>
                <div class="feed_submit" @click="addClick()">{{addBtn}}</div>
            </div>

        </div>
    </div>
</div>
    
</template>

<script>
import {getStore, setStore,getLocalStore,setLocalStore} from '@/util/store'
import {mapMutations, mapState} from "vuex";
import {Loading} from "element-ui";
import {regionData, CodeToText, TextToCode} from 'element-china-area-data';// 地址级联选择器
export default {
    name: "projectSearch",
    data() {
        return {
            range: '', //排序方式
            form: {
                userId: ''
            },
            projectList: [],    //所有项目列表
            os_projectList: [],    //搜索网签项目列表
            net_projectList: [],    //搜索互联网项目列表
            own_projectList: [],    //搜索互联网项目列表
            tagArr: {}, //标签列表
            own_tagArr: {}, //我的标签列表
            tagColor: {
                "0": '#CEF6E3',
                "1": '#EDF4FF',
                "2": '#EDF4FF',
                "3": '#EDF4FF',
                "4": '#EDF4FF',
                "5": '#FFF5ED',
                "6": '#FFEBEB'
            },
            params:{
                userSource:'',
                loginName: '',
                keyStr: '',
                pageNum:1,
                pageSize:10,
                projectSource:''
            },
            pageNum:1,
            total: 0,  //项目总数
            os_pageSize:10,
            os_pageNum:1,
            os_total:0,
            net_pageSize:10,
            net_pageNum:1,
            net_total:0,
            imgStr:[],
            imgShow:false,
            typeText:'sys', //项目类型
            isSearchSys:false,
            isSearchOwn:false,
            showSearchSys:false,
            showSearchOwn:false,
            isSearchOs:false,
            isSearchNet:false,
            sysSearchText:'展开更多',
            ownSearchText:'展开更多',
            osSearchText:'展开更多',
            netSearchText:'展开更多',
            isShowSearch: false,
            own_total:0,
            own_params:{
                userSource:'',
                loginName: '',
                keyStr: '',
                pageNum:1,
                pageSize:10,
                projectSource:'own'
            },
            isAdd:false,
            add_proName:'',
            add_city:'',
            add_province:'',
            add_county:'',
            add_company:'',
            add_address:'',
            areaData:[],
            selectedOptions:[],
            addTxt:'新建项目',
            addBtn:'新建'
        }
    },
    computed: mapState({
        pathArr: (state) => state.path, //获取导航
        navLevel: (state) => state.pathLevel //获取导航层级
    }),
    created(){
        //展现
        this.getReport(0,'','')
        this.areaData = regionData;
        if(this.utils.isDisplay('businessProject:view')){
            this.typeText = 'sys'
        }else{
            this.typeText = 'own'
        }
    },
    destroyed(){
        //销毁
        this.getReport(1,'','')
    },
    mounted: function () {
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.back, false);
        }
        if(this.typeText == 'sys'){
            this.params.loginName = getLocalStore('loginName');
            this.params.userSource = getLocalStore('userinfo').userSource;
            this.params.projectSource = getLocalStore('userinfo').userSource;
            this.getProject(this.params)
            this.own_params.loginName = getLocalStore('loginName');
            this.own_params.userSource = getLocalStore('userinfo').userSource;
            this.getOwnProject(this.own_params)
        }else{
            this.own_params.loginName = getLocalStore('loginName');
            this.own_params.userSource = getLocalStore('userinfo').userSource;
            this.getOwnProject(this.own_params)
        }
        
    },
    watch: {
        range(){
            if(this.range=='byName'){
                this.getReport(2,'choseSort','byName')
            }else if(this.range=='byrate'){
                this.getReport(2,'choseSort','bySales')
            }
        },
    },
    methods: {
        ...mapMutations(['addPath', "reducePath", "addMenuList", "reduceMenuList"]),
        back() {
            console.log('无法再次返回');
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'projectSearch',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //搜索
        search(){
            this.sysSearchText='展开更多';
            this.ownSearchText='展开更多';
            this.osSearchText='展开更多';
            this.netSearchText='展开更多';
            if(this.typeText=='sys'){
                //搜索上报
                this.getReport(2,'search','searchBtn');
                this.getProject(this.params)
                this.getOnlineSignature();
                var newParams ={
                    projectName: this.params.keyStr,
                    pageNum:this.net_pageNum,
                    pageSize:this.net_pageSize,
                };
                this.getInternet(newParams)
                this.isSearchSys = true;
                this.isSearchOs = true;
                this.isSearchNet = true;
                this.isShowSearch = true;
                this.showSearchSys = true;
            }else{
                //我的项目下进行搜索
                this.own_params.keyStr = this.params.keyStr;
                this.own_params.pageNum = 1;
                this.getOwnProject(this.own_params)
                this.getOnlineSignature();
                var newParams ={
                    projectName: this.params.keyStr,
                    pageNum:this.net_pageNum,
                    pageSize:this.net_pageSize,
                };
                this.getInternet(newParams)
                this.isSearchOwn = true;
                this.isSearchOs = true;
                this.isSearchNet = true;
                this.isShowSearch = true;
                this.showSearchOwn = true;
            }
            
        },
        //去项目概览页面
        goProOverview(item,source){
            if(source=='own' && item.isGet==0){
                this.$message.error('数据正在准备中，预计需要十分钟，请您耐心等待');
                return;
            }
            this.addPath(item.proName);
            this.addPath("项目概览");
            this.addMenuList("/projectOverview");
            setStore('thisProject',item)
            setStore("currentMenu", "/projectOverview");
            this.$router.push({path: '/projectOverview'});
        },
        goOsDetail(res){
            setStore("osData", res);
            this.addMenuList("/osdetail");
            setStore("currentMenu", "/osdetail");
            setStore("osfrom", "/projectSearch");
            this.$router.push({path: "/osdetail"});

        },
        goNetDetail(res){
            setStore("netData", res);
            this.addMenuList("/netDetail");
            setStore("netfrom", "/projectSearch");
            setStore("currentMenu", "/netDetail");
            this.$router.push({path: "/netDetail"});
        },
        //上报数据准备完成项目点击
        updateProjectDataStatus(virtualId){
            this.axios.request({
                method: "put", 
                url: '/dms/updateProjectDataStatus/'+virtualId,
               
            }).then((res) => {
            })
        },
        //项目详情页下钻
        GoProjectDetails(item,proName, id,nav,isIndex,source) {
            // this.addMenuList('/tabsContents');//加到历史菜单里，返回上一级时使用
            // setStore('currentMenu', '/tabsContents');//当前页录入存入缓存，刷新显示当页
            if(source=='own'){
                
                if(item.isGet==0){
                    this.$message.error('数据正在准备中，预计需要十分钟，请您耐心等待');
                    return;
                }else if(item.isGet==1){
                    this.updateProjectDataStatus(item.virtualId)
                }
                setLocalStore('projectForm','own')
            }else{
                setLocalStore('projectForm','sys')
            }

            this.addPath(proName);//加到导航里
            if(nav=='analysisDetails'){
                if(isIndex){
                    this.getReport(2,'listItem','main')
                }else{
                    this.getReport(2,'listItem','buildingNav')
                }
                this.addPath("建设方分析");
                this.addMenuList("/analysisDetails");
                setStore("currentMenu", "/analysisDetails");
            }else if(nav=='constructionDetails'){
                this.getReport(2,'listItem','constructionNav')
                this.addPath("施工方分析");
                this.addMenuList("/constructionDetails");
                setStore("currentMenu", "/constructionDetails");
            }else if(nav=='portraitProject'){
                this.getReport(2,'listItem','projectNav')
                this.addPath("项目画像");
                this.addMenuList("/portraitProject");
                setStore("currentMenu", "/portraitProject");
            }else if(nav=='flfxpg'){
                this.getReport(2,'listItem','riskNav')
                this.addPath("建设单位与施工单位的法律纠纷");
                this.addMenuList("/flfxpg");
                setStore("currentMenu", "/flfxpg");
            }
            this.$router.push({path: '/tabsContents'});
            setStore('thisProject',item)
            setStore('navitem',nav)
            this.$store.commit("proNameID", {proName: proName, id: id});  //保存clickInpm ndex属性到vuex中
        },
        //初始加载-取得所有项目
        getProject(params) {
            this.imgShow = false;
            this.imgStr = [];
            let getProject_login = Loading.service({background: 'rgba(0, 0, 0, 0.5)'});
            this.axios({
                method: "post", url: '/dms/getPdPro',
                 
                params: params
            }).then((res) => {
                getProject_login.close();
                if (res.data.status == "failed") {
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    
                } else if (res.data.status == "success") {
                    this.total = res.data.data.total;
                    this.projectList = res.data.data.records;
                    let that = this;
                    this.projectList.forEach(function(e){
                        that.imgStr.push('pics/'+e.id+'.jpg')
                    })
                    this.imgShow = true;
                    let tagArr = this.projectList.map(obj => {
                        return obj.dataLabel
                    })
                    tagArr.forEach(node => {
                        for (let key in node) {
                            if (node[key] == 0) {
                                delete node[key];
                            } else {
                                if (key == "0") delete node[key];
                                if (key == "1") delete node[key];
                                // if (key == "2") node[key] = "合作关系数 " + node[key] + '个';
                                if (key == "3") delete node[key];
                                if (key == "4") node[key] = "有关联关系施工单位 " + node[key] + '个';
                                if (key == "5") delete node[key];
                                if (key == "6") delete node[key];
                                if (key == "7") node[key] = "周边设施 " + node[key] + '个';
                                if (key == "8") node[key] = "土地交易信息 " + node[key] + '条';
                                if (key == "9") delete node[key];
                                if (key == "10") node[key] = "已建设项目 " + node[key] + '个';
                                if (key == "11") delete node[key];
                                if (key == "12") node[key] = "投资关系 " + node[key] + '个';
                                if (key == "13") node[key] = "风险分析 " + node[key] + '个';
                            }
                        }
                    })
                    this.tagArr = tagArr;
                }
            })
        },
        //获取我的项目
        getOwnProject(params) {
            let getProject_login = Loading.service({background: 'rgba(0, 0, 0, 0.5)'});
            this.axios({
                method: "post", url: '/dms/getPdPro',
                params: params
            }).then((res) => {
                getProject_login.close();
                if (res.data.status == "failed") {
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    
                } else if (res.data.status == "success") {
                    this.own_total = res.data.data.total;
                    this.own_projectList = res.data.data.records;
               
                    let tagArr = this.own_projectList.map(obj => {
                        return obj.dataLabel
                    })
                    tagArr.forEach(node => {
                        for (let key in node) {
                            if (node[key] == 0) {
                                delete node[key];
                            } else {
                                if (key == "0") delete node[key];
                                if (key == "1") delete node[key];
                                // if (key == "2") node[key] = "合作关系数 " + node[key] + '个';
                                if (key == "3") delete node[key];
                                if (key == "4") node[key] = "有关联关系施工单位 " + node[key] + '个';
                                if (key == "5") delete node[key];
                                if (key == "6") delete node[key];
                                if (key == "7") node[key] = "周边设施 " + node[key] + '个';
                                if (key == "8") node[key] = "土地交易信息 " + node[key] + '条';
                                if (key == "9") delete node[key];
                                if (key == "10") node[key] = "已建设项目 " + node[key] + '个';
                                if (key == "11") delete node[key];
                                if (key == "12") node[key] = "投资关系 " + node[key] + '个';
                                if (key == "13") node[key] = "风险分析 " + node[key] + '个';
                            }
                        }
                    })
                    this.own_tagArr = tagArr;
                }
            })
        },
        //获取网签数据搜索列表
        getOnlineSignature(){
            this.axios({
                method: "post", 
                url: '/networkProject/pageForProject',
                data: {
                    projectName:this.params.keyStr,
                    pageNum:this.os_pageNum,
                    pageSize:this.os_pageSize,
                }
            }).then((res) => {
              var os_data = res.data.data;
              this.os_projectList = os_data.records;
              this.os_total = os_data.total;
            })
        },
        //获取互联网数据列表
        getInternet(params){
            this.axios({
                method: "post", 
                url: '/dms/pageForInternetProject',
                data: params,
            }).then((res) => {
              var net_data = res.data.data;
              this.net_projectList = net_data.records;
              this.net_total = net_data.total;
            })
        },
        handleCurrentChange(val){
            this.params.pageNum = val;
            this.getReport(2,'paging',val)
            this.getProject(this.params);
        },
        osHandleCurrentChange(val){
            this.os_pageNum = val;
            this.getOnlineSignature();
        },
        netHandleCurrentChange(val){
            this.net_pageNum = val;
            var newParams ={
                projectName: this.params.keyStr,
                pageNum:this.net_pageNum,
                pageSize:this.net_pageSize,
            };
            this.getInternet(newParams)
        },
        ownHandleCurrentChange(val){
            this.own_params.pageNum = val;
            this.getOwnProject(this.own_params);
        },
        //切换项目类型
        projectChange(){
            this.add_proName='';
            this.add_company='';
            this.selectedOptions=[];
            this.add_address='';
            if(this.typeText=='own'){
                //获取最新我的项目
                this.own_params.pageNum = 1;
                this.getOwnProject(this.own_params)
            }
        },
        sysProMore(){
           if(!this.isSearchSys){
               this.sysSearchText='展开更多'
               this.isSearchSys = true 
           }else{
               this.sysSearchText='收起项目'
               this.isSearchSys = false 

           }
        },
        ownProMore(){
            if(!this.isSearchOwn){
               this.ownSearchText='展开更多'
               this.isSearchOwn = true 
            }else{
                this.ownSearchText='收起项目'
                this.isSearchOwn = false 

            }
        },
        osProMore(){
            if(!this.isSearchOs){
               this.osSearchText='展开更多'
               this.isSearchOs = true 
            }else{
               this.osSearchText='收起项目'
               this.isSearchOs = false 

           }
        },
        netProMore(){
            if(!this.isSearchNet){
               this.netSearchText='展开更多'
               this.isSearchNet = true 
           }else{
               this.netSearchText='收起项目'
               this.isSearchNet = false 

           }
        },
        openAdd(item){
            this.addTxt = '添加到我的项目';
            this.addBtn = '保存';
            this.add_proName = item.projectName||'';
            this.add_company = item.company||'';
            this.add_address = item.address||'';
            //省市区处理
            if(TextToCode[item.province]){
                if(TextToCode[item.province][item.city]){
                    if(TextToCode[item.province][item.city][item.county]){
                        this.selectedOptions = [TextToCode[item.province].code,TextToCode[item.province][item.city].code,TextToCode[item.province][item.city][item.county].code];
                    }else{
                        this.selectedOptions = [];
                    }
                }else{
                    this.selectedOptions = [];
                }
            }else{
                this.selectedOptions = [];
            }
            this.isAdd = true;
        },
        //添加我的项目
        addProject(item){
            this.axios({
                method: "post", 
                url: '/dms/saveProjectInfo',
                data: {
                    projectName:item.projectName,
                    taxpayerName:item.company,
                    provinceName:item.province,
                    cityName:item.city,
                    administrativeDivisionName:item.county,
                    projectAddress:item.address,
                }
            }).then((res) => {
                if(res.data.status=='success'){
                    this.$message.success('项目添加完成');
                    this.isAdd = false;
                    this.own_params.pageNum = 1;
                    this.getOwnProject(this.own_params);
                    this.add_proName='';
                    this.add_company='';
                    this.selectedOptions=[];
                    this.add_address='';
 
                }else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        addNewProject(){
            this.addTxt = '新建项目';
            this.addBtn = '新建';
            this.isAdd = true;
        },
        addClick(){
            if(this.add_proName.length==0){
                this.$message.error('请输入项目名称');
                return false;
            }else if(this.add_proName.length>20){
                this.$message.error('项目名称不能超过20个字符');
                return false;
            }else if(this.add_company.length==0){
                this.$message.error('请输入建设方名称');
                return false;
            }else if(this.add_company.length>20){
                this.$message.error('建设方名称不能超过20个字符');
                return false;
            }else if(this.selectedOptions.length==0){
                this.$message.error('请选择项目区域');
                return false;
            }else if(this.add_address.length==0){
                this.$message.error('请输入地址');
                return false;
            }else if(this.add_address.length>30){
                this.$message.error('地址不能超过30个字符');
                return false;
            }
            var feed_data = {
                projectName:this.add_proName,
                company:this.add_company,
                province:this.add_province,
                city:this.add_city,
                county:this.add_county,
                address:this.add_address,
            }
            this.addProject(feed_data)
        },
        close(){
            this.isAdd = false;
        },
        areaHandleChange (value) {
            this.add_province = CodeToText[value[0]];
            this.add_city = CodeToText[value[1]];
            this.add_county = CodeToText[value[2]];
        },
    }
}
</script>

<style scoped lang="less">
.base-container {
    overflow-y: hidden;

    .nullrelation {
        width: 250px;
        height: 156px;
        margin: 0 auto;
        background: url('../../assets/img/nullRelation.png') no-repeat;
    }

    .search-box {
        width: 1200px;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto 20px;

        .search-input-wrapper {
            display: flex;
            align-items: center;

            .search-content {
                width: 536px;
                margin-right: 16px;
            }
        }
    }

    .right-part {
        overflow: hidden;
        margin-top: 24px;
        .count-project {
            font-size: 14px;
            margin-right: 20px;
            color: #969799;
            padding-right: 24px;


            .count-num {
                display: inline;
                font-weight: bold;
                color: #323233;
                font-size: 16px;
                margin: auto 3px;
            }
        }
        .range-type {
            float: right;
            cursor: pointer;
            color: #185BCE;
            padding-top: 10px;
            /deep/ .el-input__inner {
                color: #C8C9CC;
            }
        }
    }

    .search-result-box {
        flex: 1;
        overflow: auto;

        ul {
            width: 1200px;
            margin: 0 auto;
            li {
                position: relative;
                
                box-sizing: border-box;
                background-color: #fff;
                margin: 0 0 15px 0;
                cursor: pointer;
                
                .item_box{
                    padding: 20px 20px 0 20px;
                }

                .img-project {
                    position: absolute;
                    top: 24px;
                    right: 24px;
                    width: 160px;
                    height: 90px;
                    margin-bottom: 10px;
                }

                .project-name {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 15px;
                    width: 100%;
                    height: 24px;
                    .pro_name{
                        float: left;
                    }
                    .pro_overview{
                        font-size: 14px;
                        color: #185BCE;
                        float: left;
                        line-height: 28px;
                        margin-left: 20px;
                    }
                }

                .project-info {
                    width: 1000px;
                    position: relative;
                    font-size: 14px;

                    .projec-info-item.k {
                        width: 40%;
                    }

                    .projec-info-item {
                        display: inline-flex;
                        width: 30%;
                        margin-bottom: 15px;

                        .option {
                            width: 10%;
                            min-width: 80px;
                            height: 20px;
                            text-align: left;
                            color: #999;
                        }

                        .content {
                            width: 90%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            height: 20px;
                            line-height: 20px;
                            text-align: left;
                        }
                    }
                }

                .line {
                    width: 100%;
                    height: 1px;
                    background-color: #F2F3F5;
                }
                .nav_box{
                    height: 60px;
                    display: none;
                    .nav_item{
                        width: 25%;
                        height: 60px;
                        box-sizing: border-box;
                        line-height: 58px;
                        text-align: center;
                        font-size: 16px;
                        border-right: 1px solid #EBEDF0;
                        float: left;
                    }
                    .nav_item:last-child{
                        border: none;
                    }
                    .nav_item:hover{
                        color: #185BCE;
                    }
                }
                .tag-show {
                    height: 60px;
                    padding-left: 24px;
                    .el-tag {
                        margin: 17px 10px 10px 0;
                        padding: 0;
                        width: auto;
                        display: inline-flex;


                        div {
                            display: inline-block;
                            margin: 0 5px
                        }

                        div.type5 {
                            color: #D40000;
                        }
                        div.type6 {
                            color: #ED6A0C;
                        }
                    }
                    .el-tag--medium{
                        height: 20px;
                        line-height: 20px;
                    }
                }
            }
            li:hover .tag-show{
                display: none;
            }
            li:hover .nav_box{
                display: block;
            }
        }
    }
    .page-box {
        width: 1200px;
        margin: 0 auto;
    }

    ::-webkit-scrollbar {
        width: 0;
    }
    /deep/.el-radio-button__inner{
        padding: 10px 20px;
    }
    /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
        background: #185BCE;
        border-color: #185BCE;
    }
    .list_more{
        width: 1200px;
        margin: 0 auto;
        cursor: pointer;
        text-align: center;
        font-weight: 600;
        color: #185BCE;
        font-size: 14px;
    }
    .sys_pro{
        padding-bottom: 30px;
    }
}
.hidden{
    height: 205px;
    overflow: hidden;
}
.os_hidden{
    height: 140px;
    overflow: hidden;
}
.online_sign{
    width: 1200px;
    margin: 0 auto;
}
.os_tag{
    color: #185BCE;
}
.out_tag{
    font-size: 16px;
    margin: 24px 0;
    font-weight: 600;
}
.feed_box{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    .feed_card{
        width: 740px;
        height: 400px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        .feed_header{
            width: 100%;
            height: 52px;
            border-bottom: 1px solid #DCDEE0;
            .feed_title{
                float: left;
                font-size: 14px;
                font-weight: 500;
                color: #323233;
                line-height: 52px;
                margin-left: 16px;
            }
            .feed_close{
                float: right;
                margin-right: 16px;
                margin-top: 19px;
                cursor: pointer;
            }
        }
        .feed_form{
            width: 520px;
            height: 240px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 60px;
            box-sizing: border-box;
            .feed_left{
                width: 84px;
                float: left;
                text-align: right;
                .feed_question{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                    >i{
                        color: #D40000;
                    }
                }
                .feed_proposal{
                    padding-top: 6px;
                    height: 74.4px;
                    box-sizing: border-box;
                    margin-bottom: 12px;
                }
            }
            .feed_right{
                width: 432px;
                float: right;
                .feed_radio{
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 12px;
                }
            }
        }
        .feed_btn{
            width: 168px;
            height: 32px;
            position: absolute;
            left: 282px;
            bottom: 16px;
            >div{
                float: left;
                width: 76px;
                height: 32px;
                box-sizing: border-box;
                line-height: 30px;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
            }
            .feed_cancel{
                border: 1px solid #DCDEE0;
                color: #323233;
            }
            .feed_submit{
                margin-left: 16px;
                background: #185BCE;
                border: 1px solid #185BCE;
                color: #ffffff;
            }
        }

    }
    .el-input{
        margin-bottom: 12px;
    }
    /deep/.el-input__inner{
        width: 432px;
        height: 32px !important;
        line-height: 32px !important;
    }
    .el-cascader{
        margin-bottom: 12px;
        line-height: 32px;
    }
}
.pro_tag{
    position: absolute;
    left: 0;
    top: 0;
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: 2px 0px 0px 0px;
}
.ready_bg{
    background: #FFF5ED;
    color: #ED6A0C;
}
.success_bg{
    background: #F0FAF2;
    color: #2DA641;
}
</style>
